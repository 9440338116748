<template>
  <form-field
    :name="field.name"
    :hide="field.hide"
  >
    <div>
      <span v-if="$vuetify.breakpoint.smAndDown"><small>{{ field.name }}</small></span>
      <span>
        <v-input
          v-bind="binding"
          :error="error"
        >
          {{ text }}
        </v-input>
      </span>
    </div>
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
  computed: {
    error: function () {
      return this.field.error !== null && this.field.error !== undefined
    },
    text: function () {
      return this.field.value || this.field.value === 0 ? this.field.value : '-'
    },
    binding: function () {
      const classObject = ['mb-3']

      if (!this.field.error) {
        if (this.field.emphasize) {
          classObject.push('primary')
          classObject.push('white--text')
        } else if (this.field.strong) {
          classObject.push('primary--text')
        }
      }

      !this.field.emphasize && this.$vuetify.breakpoint.mdAndUp && classObject.push('pl-0')

      const binding = { class: classObject }
      this.field.props && this.field.props.hint && Object.assign(binding, { messages: [this.field.props.hint] })
      this.field.error && Object.assign(binding, { errorMessages: [this.field.error] })

      return binding
    },
  },
}
</script>
